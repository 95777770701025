import React from "react"

import Seo from "../../../components/SEO"
import Layout from "../../../components/Layout"
import { Main, Container } from "../../../components/UI"
import SectionTitle from "../../../components/SectionTitle"

const SuccessPage = () => {
  return (
    <Layout>
      <Seo title="Login Successful" noIndex />
      <Main className="bg-white">
        <section className="pt-28 font-spartan">
          <Container>
            <SectionTitle>Login Successful</SectionTitle>
            <p className="text-center">
              Please check the mobile application. You are now logged in.
            </p>
          </Container>
        </section>
      </Main>
    </Layout>
  )
}

export default SuccessPage
